import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import meta from "vue-meta";

createApp(App)
  .use(store)
  .use(router)
  .use(meta)
  .mount("#app");

export default {
  name: "App",
  metaInfo: {
    title: "xxxtapas",
    titleTemplate: "%s ← Shuffle Player",
    meta: [
      { name: "hubtraffic-domain-validation", content: "a2c3e261592b8daa" },
    ],
  },
};